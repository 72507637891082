import React from 'react';
import { graphql } from "gatsby"
import ReactMarkdown from 'react-markdown';
import Layout from "../components/layout"
import Header from '../components/shared/header';
import Helmet from 'react-helmet';

export default ({ data }) => {
  let content = data.article.content;
  content = content.replace(/\#/g, '## ');
  content = content.replace(/\\n/g, '\n ');
  
  let socialMediaDescription = data.article.content;
  socialMediaDescription = socialMediaDescription.replace(/\#/g, '');
  socialMediaDescription = socialMediaDescription.replace(/\\n/g, '');

  return (
    <Layout>
      <Helmet>
        <title>KnowDrugs: {data.article.name}</title>
        <meta property="og:description" content={socialMediaDescription} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:description" content={socialMediaDescription} />
        <meta property="twitter:title" content={`KnowDrugs: ${data.article.name}`} />
        <meta property="twitter:site" content="@know_drugs" />
      </Helmet>
      <Header category="Emergencies" title={data.article.name} />
      <ReactMarkdown 
        source={content}
        className="markdownContainer" 
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    article(fields: { slug: { eq: $slug } }) {
      name,
      content
    }
  }
`
